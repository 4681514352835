
<template>
  <div>
    <v-container class="text-center mt-10">
      <h1>Verifikasi WhatsApp dan </h1>
      <h1>Meminta Admin BKPSDM untuk mengaktifkan Akun</h1>
      <p class="mt-10">Silakan tekan tombol di bawah untuk verifikasi nomor WhatsApp Anda.</p>
      <div CLASS>
        <v-btn
            color="primary"
            @click="submit"
        >Meminta Aktivasi</v-btn>
      </div>
      <p class="mt-10 font-italic">Pastikan Nomor Whatsapp Anda Benar</p>
    </v-container>
  </div>
</template>

<script>
import UserService from "@/services/user-service";
import User from "@/models/user";

export default {
  name: 'VerifikasiLoginLinkView',
  components: {},
  data() {
    return {
      timeMilis: 0,
      requestId: 0,
      itemModified: new User("", "")
    }
  },
  methods: {
    submit() {
      this.itemModified.remark1 = "*WhatsApp Verified#"
      UserService.updateUserRemark1(this.itemModified).then(
          () =>{
            this.$router.push('/verifikasi-login-link-send');
          },
          error =>{
            console.log(error)
          }
      )

    }
  },
  mounted() {
    const time_milis = this.$route.params.time_milis;
    const id = this.$route.params.id;
    this.timeMilis = time_milis;
    this.requestId = id
    this.itemModified.id = id

    // UserService.getUserById(this.requestId).then(
    //     response =>{
    //       this.itemModified = response.data;
    //     },
    //     error =>{
    //       console.log(error)
    //     }
    // )

  }
}
</script>

<style scoped>

</style>